import { AppServicesCardDto } from "dto/components/app-services-card-dto";

const AppServicesCard = ({ title, image }: AppServicesCardDto) => {
	const match = title.match(/^(.*?)(\s*\(.*\))?$/);
	const mainTitle = match?.[1] || title;
	const bracketText = match?.[2] || "";

	return (
		<div className="app-services-card">
			<div className="services-card">
				<div className="services-card__header">
					<div className="services-card__wrapper">
						<img className="services-card__icon" src={image} alt="icon" />
					</div>

					<p className="services-card__title">
						<span>{mainTitle}</span>
						{bracketText && <span className="services-card__title--bracket">{bracketText}</span>}
					</p>
				</div>
			</div>
		</div>
	);
};

export default AppServicesCard;
