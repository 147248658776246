import classNames from "common/class-names";

import { AppHeaderCardDto } from "dto/components/app-header-card-dto";

const HeaderCard = ({ backgroundImage, title, headerType = "main" }: AppHeaderCardDto) => {
	const imageClassName = classNames({
		"app-header-card__image": true,
		"app-header-card__image--post": headerType === "post",
	});

	const backgroundGradient =
		headerType === "post"
			? `linear-gradient(
					180deg,
					rgba(20, 20, 20, 0),
					rgba(20, 20, 20, 0.88) 99.99%,
					rgba(20, 20, 20, 1)
				)`
			: `linear-gradient(
					180deg,
					rgba(20, 20, 20, 0),
					rgba(20, 20, 20, 0.5) 99%,
					rgba(20, 20, 20, 0.5)
				)`;

	return (
		<div className="app-header-card">
			<div
				className={imageClassName}
				style={{
					backgroundImage: `${backgroundGradient}, url(${backgroundImage})`,
				}}
			>
				<p className={`app-header-card__title ${headerType}`}>{title}</p>
			</div>
		</div>
	);
};

export default HeaderCard;
