import { useNavigate } from "react-router-dom";

import AppArticleCardDto from "dto/components/app-article-card-dto";

const AppArticleCard = ({ title, description, image, link, date }: AppArticleCardDto) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(link);
	};

	const formattedDate = date ? new Intl.DateTimeFormat("en-GB", { day: "numeric", month: "short", year: "numeric" }).format(new Date(date)) : null;

	return (
		<div className="app-article-card-wrapper">
			<div className="app-article-card">
				<div className="article-card">
					<img className="article-card__image" src={image} alt="articleImage" onClick={handleClick} />

					<p className="article-card__title">{title}</p>

					<p className="article-card__description">
						{description} {date && <span>|</span>} {formattedDate}
					</p>
				</div>
			</div>
		</div>
	);
};
export default AppArticleCard;
