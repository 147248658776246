//library
import { useCallback, useMemo, useState, useEffect, useRef } from "react";

//services
import api from "services/api";

// hooks
import useWindowSize from "hooks/use-window-size";
import useTemplateContext from "hooks/use-template-context";

//common
import serveRequestErrors from "common/serve-request-errors";

//dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { ImperativeHandleCategoryTabDto } from "dto/components/app-category-tab-dto";
import { SolutionCardDto, SolutionBrochureDto } from "dto/pages/page-all-products-dto";

//components
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppNewsCard from "components/app-news-card";
import HeaderCard from "components/app-header-card";
import AppPagination from "components/app-pagination";
import AppServicesCard from "components/app-services-card";
import AppBrochureCard from "components/app-brochure-card";
import AppCategoryTab from "components/app-category-tab";

const PageAllProducts = () => {
	const sizes = useWindowSize();
	const queryParams = useMemo(() => ({ search: "", page: 0, size: 100 }), []);
	const tabRef = useRef<ImperativeHandleCategoryTabDto>(null);

	const [currentPage, setCurrentPage] = useState(0);
	const [currentBrochurePage, setCurrentBrochurePage] = useState(0);
	const [currentSolutionPage, setCurrentSolutionPage] = useState(0);
	const [autoService, setAutoService] = useState(true);
	const [autoBrochure, setAutoBrochure] = useState(true);
	const [autoSolution, setAautoSolution] = useState(true);
	const [servicesCard, setServicesCard] = useState<SolutionCardDto[]>([]);
	const [brochureData, setBrochureData] = useState<SolutionBrochureDto[]>([]);
	const [categoriesTitleData, setCategoriesTitleData] = useState<SolutionCardDto[]>([]);
	const [solutionBrochureData, setSolutionBrochureData] = useState<SolutionBrochureDto[]>([]);
	const { getFilteredResources } = useTemplateContext();

	const costumeWidth = sizes?.[0];
	const pageConstant = useMemo(() => (costumeWidth <= 500 ? 1 : 4), [costumeWidth]);
	const totalPageNumber = useMemo(() => (Array.isArray(servicesCard) ? Math.ceil(servicesCard.length / 9) : 0), [servicesCard]);
	const paginatedServices = useMemo(() => servicesCard.slice(currentPage * 9, (currentPage + 1) * 9), [currentPage, servicesCard]);
	const totalBrochurePageNumber = useMemo(() => Math.ceil(brochureData.length / pageConstant), [brochureData.length, pageConstant]);
	const paginatedBrochure = useMemo(() => brochureData.slice(currentBrochurePage * pageConstant, (currentBrochurePage + 1) * pageConstant), [brochureData, currentBrochurePage, pageConstant]);
	const totalSolutionPageNumber = useMemo(() => Math.ceil(solutionBrochureData.length / pageConstant), [pageConstant, solutionBrochureData.length]);
	const paginatedSolution = useMemo(() => solutionBrochureData.slice(currentSolutionPage * pageConstant, (currentSolutionPage + 1) * pageConstant), [currentSolutionPage, pageConstant, solutionBrochureData]);

	const servicePage = useMemo(() => getFilteredResources("page.service"), [getFilteredResources]);
	const getPageData = useCallback((key: any) => servicePage?.find((value) => value.key === key)?.value?.toString() ?? "", [servicePage]);
	const serviceCoverHeader = useMemo(() => getPageData("page.service.cover.header"), [getPageData]);
	const serviceCover = useMemo(() => getPageData("page.service.cover"), [getPageData]);
	const brochureHeader = useMemo(() => getPageData("page.service.brochure.header"), [getPageData]);
	const brochureDescription = useMemo(() => getPageData("page.service.brochure.description"), [getPageData]);
	const serviceBrochure = useMemo(() => getPageData("page.service.brochure.service"), [getPageData]);
	const solutionBrochure = useMemo(() => getPageData("page.service.brochure.solution"), [getPageData]);

	const onHandleNext = useCallback(() => {
		setAutoService(false);
		if (currentPage !== totalPageNumber - 1) {
			setCurrentPage((prev) => ++prev);
		}
	}, [currentPage, totalPageNumber]);

	const onHandleBack = useCallback(() => {
		setAutoService(false);
		if (currentPage !== 0) {
			setCurrentPage((prev) => --prev);
		}
	}, [currentPage]);

	const onHandleBrochureNext = useCallback(() => {
		setAutoBrochure(false);
		if (currentBrochurePage !== totalBrochurePageNumber - 1) {
			setCurrentBrochurePage((prev) => ++prev);
		}
	}, [currentBrochurePage, totalBrochurePageNumber]);

	const onHandleBrochureBack = useCallback(() => {
		setAutoBrochure(false);
		if (currentBrochurePage !== 0) {
			setCurrentBrochurePage((prev) => --prev);
		}
	}, [currentBrochurePage]);

	const onHandleSolutionNext = useCallback(() => {
		setAautoSolution(false);
		if (currentSolutionPage !== totalSolutionPageNumber - 1) {
			setCurrentSolutionPage((prev) => ++prev);
		}
	}, [currentSolutionPage, totalSolutionPageNumber]);

	const onHandleSolutionBack = useCallback(() => {
		setAautoSolution(false);
		if (currentSolutionPage !== 0) {
			setCurrentSolutionPage((prev) => --prev);
		}
	}, [currentSolutionPage]);

	const fetchData = useCallback(async (apiCall: any, setter: any) => {
		try {
			const response = await apiCall();
			setter(response.data?.data?.list?.content ?? []);
			return response;
		} catch (error) {
			const err = error as Error | ErrorResponseDto;
			serveRequestErrors(err);
		}
	}, []);

	//prettier-ignore
	const onHandleGetServices = useCallback(async (id: string) => {
		const params = queryParams;

		await fetchData(() => api.get.services.servicesCard(id, params), setServicesCard);
		setAutoService(true);
	}, [queryParams, fetchData]);

	//prettier-ignore
	const onHandleSelect = useCallback((tab: any) => {
		const categoryId = tab.id;

		setCurrentPage(0);

		onHandleGetServices(categoryId);
	}, [onHandleGetServices]);

	const onHandleGetCategoriesTitle = useCallback(async () => {
		const params = queryParams;

		const response = await fetchData(() => api.get.solutions.solutionTitle({ size: 12, page: params.page }), setCategoriesTitleData);

		const dataContent: SolutionCardDto[] = response.data.data.list.content;

		const mapDataContent = dataContent.map((m, i) => ({ ...m, indexKey: i }));

		setCategoriesTitleData(mapDataContent);

		onHandleGetServices(mapDataContent[0].id);
	}, [queryParams, fetchData, onHandleGetServices]);

	const onHandleGetBrochureData = useCallback(async () => {
		const params = queryParams;

		const payload = { size: 100, page: params.page };

		await fetchData(() => api.get.solutions.brochure("services", payload), setBrochureData);
	}, [queryParams, fetchData]);

	const onHandleGetSolutionBrochureData = useCallback(async () => {
		const params = queryParams;

		const payload = { size: 100, page: params.page };

		await fetchData(() => api.get.solutions.brochure("solutions", payload), setSolutionBrochureData);
	}, [queryParams, fetchData]);

	useEffect(() => {
		onHandleGetCategoriesTitle();
		onHandleGetBrochureData();
		onHandleGetSolutionBrochureData();
	}, [onHandleGetCategoriesTitle, onHandleGetBrochureData, onHandleGetSolutionBrochureData]);

	return (
		<div className="page-all-products">
			<div className="products">
				<AppNavbar />

				<HeaderCard backgroundImage={serviceCover} title={serviceCoverHeader} />

				<div className="tabs-section">{categoriesTitleData.length > 0 && <AppCategoryTab ref={tabRef} tabs={categoriesTitleData} onSelect={onHandleSelect} />}</div>

				{categoriesTitleData.length > 0 && (
					<div className="services-section">
						<p className="services-section__title">{tabRef.current?.selectedDetails[0]?.title}</p>

						<div className="services-section__after">
							<div className="services-section__header">
								<p className="services-section__description">{tabRef.current?.selectedDetails[0]?.fullDescription?.replace(/-/g, "‑") || tabRef.current?.selectedDetails[0]?.description?.replace(/-/g, "‑")}</p>

								{totalPageNumber > 1 && (
									<AppPagination
										totalPageNumber={totalPageNumber}
										currentPage={currentPage}
										isLoop={autoService}
										onAuto={(page: number) => setCurrentPage(page)}
										onHandleNext={() => onHandleNext()}
										onHandleBack={() => onHandleBack()}
									/>
								)}
							</div>

							{paginatedServices.length > 0 && (
								<div className="services-section__wrapper">
									{paginatedServices.map((o) => (
										<AppServicesCard title={o.title} image={o.logo} key={o.id} />
									))}
								</div>
							)}
						</div>
					</div>
				)}

				<div className="services-and-solutions-section">
					<p className="services-and-solutions-section__title">{brochureHeader}</p>

					<div className="services-and-solutions-section__header">
						<p className="services-and-solutions-section__description">{brochureDescription}</p>
					</div>

					<div className="service-brochure-section">
						<p className="service-brochure-section__service-title">{serviceBrochure}</p>
						{totalSolutionPageNumber > 1 && (
							<AppPagination
								totalPageNumber={totalBrochurePageNumber}
								currentPage={currentBrochurePage}
								isLoop={autoBrochure}
								onAuto={(page: number) => setCurrentBrochurePage(page)}
								onHandleNext={() => onHandleBrochureNext()}
								onHandleBack={() => onHandleBrochureBack()}
							/>
						)}
					</div>

					<div className="service-brochure-section__wrapper">
						{paginatedBrochure.map((o) => (
							<div className="service-brochure-section__box" key={o.id}>
								<AppBrochureCard title={o.title} image={o.cover} link={o.document} key={o.id} />
							</div>
						))}
					</div>

					<div className="service-brochure-section">
						<p className="service-brochure-section__solution-title">{solutionBrochure}</p>

						{totalSolutionPageNumber > 1 && (
							<AppPagination
								totalPageNumber={totalSolutionPageNumber}
								currentPage={currentSolutionPage}
								isLoop={autoSolution}
								onAuto={(page: number) => setCurrentSolutionPage(page)}
								onHandleNext={() => onHandleSolutionNext()}
								onHandleBack={() => onHandleSolutionBack()}
							/>
						)}
					</div>

					<div className="service-brochure-section__wrapper">
						{paginatedSolution.map((o) => (
							<div className="service-brochure-section__box" key={o.id}>
								<AppBrochureCard title={o.title} image={o.cover} link={o.document} key={o.id} />
							</div>
						))}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />
			</div>
		</div>
	);
};

export default PageAllProducts;
