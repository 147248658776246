const END_POINT_PATH = {
	SOLUTIONS: {
		SOLUTIONS: "solution/search",
		SOLUTION: "/solution/categorySearch",
		SOLUTION_TITLE: "solutionCategories/search",
		BROCHURE: "/brochure/searchMain/",
	},
	CLIENTS: {
		CLIENTS: "/client/list",
		CLIENT: "/client/",
	},
	ARTICLE: {
		ARTICLE_DETAILS: "/article/",
		SIMILAR_ARTICLE: "/article/categorySearch/",
		OUR_ARTICLES: "/article/search/",
		CATEGORY_TITLE: "/article/category/categoryTitle",
		ALL_ARTICLES: "/article/listArticle/",
		LATEST_ARTICLES: "/article/searchArticle/",
	},
	FAQ: {
		FAQ: "/faq/list",
	},
	SERVICES: {
		SERVICES: "/solutionCategories/categorySearch/",
		SERVICES_CARD: "/solution/categorySearch/",
	},
	PARTNER: {
		PARTNER: "/partner/list",
	},
	CAREER: {
		CAREER: "/career/search/",
		FORM: "/career/career-form",
		HIRING: "/career/hiring",
		POSITION: "/career/list",
		HIRING_SEARCH: "/career/hiringSearch/hiringStatus",
	},
	BRANCH: {
		BRANCH: "/branch/retrieve",
	},
	DROPDOWN: {
		RETRIEVE_CATEGORY: "/dropdown/retrieve/",
	},
	NEWSLETTER: {
		FORM: "/newsletter/email",
	},
	RESOURCE: {
		TEMPLATE: "/resource/active/template",
		RESOURCE: "/resource/find/customer/template/",
	},
};

export default END_POINT_PATH;
