import services from "services";

import encodeQueryParams from "common/encode-query-params";

import END_POINT_PATH from "constants/end-point-path";

// dto
import ResourceDataDto from "dto/services/resource-data-dto";
import PageConnectWithUsDto from "dto/pages/page-connect-with-us-dto";

const xApiKey = process.env.REACT_APP_X_API_KEY;

const api = {
	get: {
		solutions: {
			solutions: (params: { param: string; size: number; page: number }) => services.get(END_POINT_PATH.SOLUTIONS.SOLUTIONS + encodeQueryParams(params)),
			solutionTitle: (params: { size: number; page: number }) => services.get(END_POINT_PATH.SOLUTIONS.SOLUTION_TITLE + encodeQueryParams(params)),
			brochure: (type: string, params: { size: number; page: number }) => services.get(END_POINT_PATH.SOLUTIONS.BROCHURE + `${type}` + encodeQueryParams(params)),
		},
		faq: {
			faq: () => services.get(END_POINT_PATH.FAQ.FAQ),
		},
		ourArticles: {
			ourArticles: (params: { param: string; size: number; page: number }) => services.get(END_POINT_PATH.ARTICLE.OUR_ARTICLES + encodeQueryParams(params)),
			allArticles: (params: { param: string; size: number; page: number }) => services.get(END_POINT_PATH.ARTICLE.ALL_ARTICLES + encodeQueryParams(params)),
			latestArticles: (params: { param: string; size: number; page: number }) => services.get(END_POINT_PATH.ARTICLE.LATEST_ARTICLES + encodeQueryParams(params)),
		},
		clients: {
			client: (id: string) => services.get(END_POINT_PATH.CLIENTS.CLIENT + id),
			clients: () => services.get(END_POINT_PATH.CLIENTS.CLIENTS),
		},
		partner: {
			partner: () => services.get(END_POINT_PATH.PARTNER.PARTNER),
		},
		articleDetails: {
			article: (id: string) => services.get(END_POINT_PATH.ARTICLE.ARTICLE_DETAILS + id),
		},
		similarArticle: {
			article: (category: string, params: { page: number; size: number }) => services.get(END_POINT_PATH.ARTICLE.SIMILAR_ARTICLE + `${category}` + encodeQueryParams(params)),
		},
		services: {
			services: (main: string, params: { size: number; page: number }) => services.get(END_POINT_PATH.SERVICES.SERVICES + `${main}` + encodeQueryParams(params)),
			servicesCard: (id: string, params: { size: number; page: number }) => services.get(END_POINT_PATH.SERVICES.SERVICES_CARD + `${id}` + encodeQueryParams(params)),
		},
		categoryTitle: {
			categoryTitle: () => services.get(END_POINT_PATH.ARTICLE.CATEGORY_TITLE),
		},
		dropdown: {
			retrieveCategory: (category: string, params: { page: number; size: number }) => services.get(END_POINT_PATH.DROPDOWN.RETRIEVE_CATEGORY + `${category}` + encodeQueryParams(params)),
		},
		branches: (status: string, params: { page: number; size: number }) => services.get(`${END_POINT_PATH.BRANCH.BRANCH}/${status}` + encodeQueryParams(params)),
		career: {
			career: (params: { param: string; size: number; page: number }) => services.get(END_POINT_PATH.CAREER.CAREER + encodeQueryParams(params)),
			hiring: () => services.get(END_POINT_PATH.CAREER.HIRING),
			position: () => services.get(END_POINT_PATH.CAREER.POSITION),
			hiringSearch: (params: { size: number; page: number }) => services.get(END_POINT_PATH.CAREER.HIRING_SEARCH + encodeQueryParams(params)),
		},
		resource: {
			template: () => services.get(END_POINT_PATH.RESOURCE.TEMPLATE, { headers: { "X-Api-Key": xApiKey } }),
			resource: (template: string) => services.get<ResourceDataDto>(END_POINT_PATH.RESOURCE.RESOURCE + `${template}`, { headers: { "X-Api-Key": xApiKey } }),
		},
	},
	post: {
		contactUs: (formData: PageConnectWithUsDto) => services.post("/enquiry/save", formData),
		career: {
			form: (formData: FormData) => services.post(END_POINT_PATH.CAREER.FORM, formData, { headers: { "Content-Type": "multipart/form-data" } }),
		},
		newsLetter: {
			form: (formData: FormData) => services.post(END_POINT_PATH.NEWSLETTER.FORM, formData),
		},
	},
	patch: {},
	put: {},
};

export default api;
